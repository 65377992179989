/* Preloader */

.loaderContainer{
  height: 100vh;
  width: 100%;
  margin: 0 auto;

  .loader{
    position: absolute;
    top:50%;
    left: 50%;
  }
}