.mainContainer {

  .select{
    width: 150px;
  }

  .rowDeleted{
    opacity: 0.4;
    text-decoration: line-through;
  }
  
}