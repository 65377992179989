.fundrNameCol {
  max-width: 160px;
  min-width: 70px;

  a {
    color: black;

    &:hover {
      color: black;
    }
  }
}

.intercomCol {
  display: flex;
  justify-content: left;
  width: 30px;
}

.activeCol {
  min-width: 80px;
}

.schoolCol {
  display: flex;
  min-width: 130px;
  max-width: 200px;

  a {
    color: black;

    &:hover {
      color: black;
    }
  }

  .locationIcon {
    padding-right: 3px;

    .markerIcon {
      width: 15px;
      opacity: 0.5;
    }
  }
}

.adminEventCol {
  display: flex;
  align-items: center;
  min-width: 120px;
  max-width: 210px;
  word-break: break-all;

  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, .03);
    border: 1px solid #dadada;
    border-radius: 100%;

    img {
      width: 38px;
      height: 38px;
    }
  }

  .adminInfo {
    display: grid;
    gap: 0px;
    padding-left: 5px;
  }
}

.empty {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
}

.statusCol {
  max-width: 60px;
}

.status {
  max-width: 90px;

  div {
    display: inline-block;
    min-width: 80px;
    align-items: center;
    text-transform: capitalize;
    text-align: center;
    font-size: 13px;
    padding: 2px 8px;
    border-radius: 10rem;
    white-space: nowrap;
  }
}

.closed {
  background: #b9d4cc;
  color: #fff;
}



.deleted {
  background: #ccc;
  color: #fff;
}

.row-deleted {
  opacity: 0.4;
  text-decoration: line-through;
}

.unapproved {
  background: #fcb202;
  color: #fff;
}

.fundraising {
  background: #6bc66e;
  color: #fff;
}

.approved {
  background: #fff;
  border: 1px solid #ccc;
  color: #333;
}

.preparing {
  background: #0689D4;
  color: #fff;
}

.columnStatus {
  width: 2%;
  max-width: 60px;
}

.modal {
  display: grid;
  font-weight: bold;
}

.row-deleted {
  opacity: 0.4;
  text-decoration: line-through;
}

.editIcon {

  img {
    cursor: pointer;
    display: flex;
    opacity: 0.5;

    justify-content: center;
    align-items: center;
    float: right;
    border-color: transparent;
    background: transparent;
    box-shadow: none;
    font-size: 12px;
    border-radius: 18px;
    outline: none !important;
    transition: 0.5s;
    margin-right: 5px;
    width: 40px;
    height: 40px;
    padding: 10px;
  }
}
.editIcon img:hover {
  background-color: #e2e2e2;
  transition: 0.5s;
}

.sortCol{
  display: flex;
  div {
    font-size: 10px;
    font-weight: 400;
  }

  img{
    margin-left: 5px;
    opacity: 0.4;
    width: 12px;
  }

  .active {
    margin-left: 5px;
    opacity: 1;
  }
}