
.filtersContainer {

  .select {
    width: 155px;
  }

  .select + .select {
    margin-left: 20px;
  }

}

.negative{
  color: #b76007;
}

.bookBox{
  color: #9f02c2;
}

.disabled{
  color: #9b9b9b;
}


td > a {
  text-decoration: underline;
}

.chart {
  min-width: 500px;
  max-width: 700px;
}

.chartTooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}

.donorMessage {
  span{
    max-width: 400px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }

}