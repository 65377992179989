


.wrapper {
  background-color: #0689D4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}


.infoBadge {
  position: fixed;
  left: 50px;
  top: 50px;
  font-size: 12px;
  max-width: 220px;

  color: rgba(255, 255, 255, 0.75);
}

.infoBadge strong{
  color: white;
  font-size: 11px;
}

.infoBadge img {
  width: 120px;
  margin-bottom: 20px;
}


.logo{
  img{
    width: 200px;
    margin-bottom: 50px;
  }

}


.dialog {
  width: 400px;
  background-color: white;
  min-height: 200px;
  text-align: center;
  border-radius: 5px;
  padding: 40px 40px;
}

.dialog h2 {
  font-weight: 600;
  font-size: 18px;
}
.dialog p {
  color: #646464;
  margin-bottom: 40px;
}

.loginInfo{
  font-size: 12px;
  color: #707070;
}


