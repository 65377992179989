.statusColumn {
  label {
    color: #fff;
    border-radius: 20px;
    padding: 2px 10px;

    &.draft {
      background-color: #8ca5b0;
    }

    &.published {
      background-color: #6bc66e;
    }
    &.archived {
      background-color: #d9d9d9;
    }
  }
};

.titleCol{
  color: #327AB7;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
};

.rowDeleted{
  opacity: 0.4;
  text-decoration: line-through;
};

.select{
  width: 120px;
};
