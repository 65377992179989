
.wrapper {

  section {
    display: flex;
    padding: 14px 0;
    //align-items: center;
    border-bottom: 1px solid #ececec;


    .label {
      display: flex;
      font-size: 12px;
      font-weight: 600;
      min-width: 220px;

    }

    .desc {
      color: #7c7c7c;

      a {
        font-size: 12px;
        text-decoration: underline;
        color: #7c7c7c;
      }
    }
  }

}

.buttonIcon{
  //margin-right: 10px;
  margin-left: 10px;
  width: 12px;
  height: 12px;

}
.status {
  display: inline-block;
  //min-width: 120px;
  //align-items: center;
  //text-align: center;
  cursor: pointer;
  padding: 4px 10px;
  margin-right: 10px;
  border-radius: 4px;
  text-transform: capitalize;
  white-space: nowrap;

  border: 1px solid #ccc;

  &.fundraising {
    background: #6bc66e;
    color: #fff;
  }

  &.approved {
    background: #fff;
    color: #333;
  }

  &.preparing {
    background: #0689D4;
    color: #fff;
  }

  &.deleted {
    background: #dedede;
    color: #fff;
  }

  &.closed {
    background: #b9d4cc;
    color: #fff;
  }


}
