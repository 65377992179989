.mainContainer{

  .roleSelect{
    width: 120px;
  }

  .ant-input{
    background: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    border-radius: 2px;
  }

  .searchBtn{
    background: #0689d4;
    color: #fff;
  }

}

.rowDeleted{
  opacity: 0.4;
  text-decoration: line-through;
}


