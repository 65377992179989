.mainContainer{

  .searchInput{
    width: 275px;
  }

  :global{
    .ant-table-tbody > tr > td {
      padding: 10px 18px;
    }
  }
}

.valueColumn{
  max-width: 600px;

  .textValue{
    white-space: pre-line;
    font-size: 90%;
    font-weight: lighter;
  }
}

.parametrColumn{
  font-weight: bold;
}